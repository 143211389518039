// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
// Images
import bgImage from "assets/images/bg-presentation.webp";
import { Container, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import React from "react";
import { getTeachersDetails } from "firebaseFunctions";
import "./teacher.css";

function Teachers() {
  const [data, setData] = React.useState([]);

  const getData = async () => {
    try {
      const res = await getTeachersDetails();
      setData(res);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Teachers
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox sx={{ margin: "auto" }}>
          <table style={{ width: "fit-content", maxWidth: "850px", border: "1px solid" }}>
            <thead>
              <tr>
                <th>Photo</th>
                <th>Name</th>
                <th>Qualification</th>
                <th>Experience</th>
                <th>Classes / Subjects / Designation</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((row) => (
                  <tr key={row.id}>
                    <td>
                      <img src={row.photo} alt="home tutors" style={{ width: "70px" }} />
                    </td>
                    <td>{row.name}</td>
                    <td>{row.qualification}</td>
                    <td>{row.experience}</td>
                    <td>{row.subjects}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Teachers;
