// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.webp";
import bruceMars from "assets/images/Portrait_Placeholder.png";
import React from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { writePlacementData } from "../../../firebaseFunctions";

function JobPlacement() {
  //   const [showForm, setShowForm] = React.useState(false);
  const [event, setEvent] = React.useState();
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [image, setImage] = React.useState(bruceMars);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (event && event.name) || "",
      email: (event && event.email) || "",
      refferal: (event && event.refferal) || "",
      age: (event && event.age) || "",
      gender: (event && event.gender) || "",
      fatherName: (event && event.fatherName) || "",
      currentAddress: (event && event.currentAddress) || "",
      permanentAddress: (event && event.permanentAddress) || "",
      mobileNo: (event && event.mobileNo) || "",
      alternateMobileNo: (event && event.alternateMobileNo) || "",
      experience: (event && event.experience) || "",
      prefferedArea: (event && event.prefferedArea) || "",
      salaryExpected: (event && event.salaryExpected) || "",
      profile: (event && event.profile) || "",
      maritalStatus: (event && event.maritalStatus) || "",
      regDate: (event && event.regDate) || "",
      qualification: (event && event.qualification) || "",
      idProof: (event && event.idProof) || "",
      photo: (event && event.photo) || bruceMars,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Field is required"),
      email: Yup.string().email("Invalid email address").required("Field is required"),
      age: Yup.string().required("Field is required"),
      gender: Yup.string().required("Field is required"),
      fatherName: Yup.string().required("Field is required"),
      currentAddress: Yup.string().required("Field is required"),
      permanentAddress: Yup.string().required("Field is required"),
      mobileNo: Yup.string().required("Field is required"),
      alternateMobileNo: Yup.string().required("Field is required"),
      experience: Yup.string().required("Field is required"),
      prefferedArea: Yup.string().required("Field is required"),
      salaryExpected: Yup.string().required("Field is required"),
      profile: Yup.string().required("Field is required"),
      maritalStatus: Yup.string().required("Field is required"),
      regDate: Yup.string().required("Field is required"),
      qualification: Yup.string().required("Field is required"),
      idProof: Yup.string().required("Field is required"),
      photo: Yup.string().required("Field is required"),
    }),

    onSubmit: async (values) => {
      setSubmitLoader(true);
      try {
        const result = await writePlacementData(values);
        setSubmitLoader(false);
        setImage(bruceMars);
        setEvent({});
        validation.resetForm();
        // setShowForm(false);
        alert("Submit successfully");
        console.log(result);
      } catch (error) {
        console.log(error);
        setSubmitLoader(false);
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 100000) {
      alert("Size is not more than 100KB");
    } else if (
      file.type != "image/png" &&
      file.type != "image/jpg" &&
      file.type != "image/jpeg" &&
      file.type != "image/webp"
    ) {
      alert("Image should be png, jpg, jpeg or webp");
    } else {
      setImage(URL.createObjectURL(file));
      validation.setFieldValue("photo", file);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 100000) {
      alert("Size is not more than 100KB");
    } else if (
      file.type != "application/pdf" &&
      file.type != "image/png" &&
      file.type != "image/jpg" &&
      file.type != "image/jpeg" &&
      file.type != "image/webp"
    ) {
      alert("File should be in PDF format");
    } else {
      validation.setFieldValue("idProof", file);
    }
  };

  React.useEffect(() => {
    setEvent({});
  }, []);
  console.log(validation);
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Job Placement
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 5,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {submitLoader ? (
          <MKBox
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}
          >
            <CircularProgress />
          </MKBox>
        ) : (
          <>
            {/* <MKTypography variant="p" color="#344767">
              <MKTypography variant="span" fontWeight={800} color="#344767">
                <b>Declarations:</b>
              </MKTypography>{" "}
              Tutor Registration I promise and contemplate that, I will not maintain direct
              relationship along with parents & other Guardian of tution and I will solemnly follow
              and maintain all the conditions or instructions issued or prescribed by A1 Home Tutor.
              I also promise to maintain good will and loyality towards A1 Home Tutor. In case of
              infraction of any law and instructions presribed by A1 Home Tutor.
            </MKTypography> */}
            {/* {!showForm && (
              <MKBox sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <MKButton
                  variant="gradient"
                  color="info"
                  size="small"
                  width="fit-content"
                  onClick={() => setShowForm(true)}
                >
                  I Agree, Proceed
                </MKButton>
              </MKBox>
            )} */}
            {/* {showForm && ( */}
            <MKBox sx={{ mt: 5 }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <MKBox>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                      <FormControl
                        fullWidth
                        error={validation.touched.photo && validation.errors.photo}
                      >
                        <MKBox sx={{ width: "200px", height: "200px", border: "1px solid #000" }}>
                          <img
                            src={image}
                            alt="Uploaded"
                            style={{ width: "200px", height: "200px" }}
                          />
                        </MKBox>
                        <MKBox>
                          <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                            Upload your photo (max size 100KB and format png, jpg, jpeg and webp)
                            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                          </Typography>
                          <Input type="file" onChange={handleImageChange} />
                        </MKBox>
                        {validation.touched.photo && validation.errors.photo ? (
                          <FormHelperText>{validation.errors.photo}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <FormControl
                        fullWidth
                        error={validation.touched.name && validation.errors.name}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Name
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="name"
                          fullWidth
                          value={validation.values.name || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormHelperText>{validation.errors.name}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <FormControl
                        fullWidth
                        error={validation.touched.email && validation.errors.email}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Email
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="email"
                          fullWidth
                          value={validation.values.email || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormHelperText>{validation.errors.email}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <FormControl
                        fullWidth
                        error={validation.touched.refferal && validation.errors.refferal}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Refferal
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="refferal"
                          fullWidth
                          value={validation.values.refferal || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.refferal && validation.errors.refferal ? (
                          <FormHelperText>{validation.errors.refferal}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <FormControl
                        fullWidth
                        error={validation.touched.age && validation.errors.age}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Age
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="age"
                          type="number"
                          fullWidth
                          value={validation.values.age || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.age && validation.errors.age ? (
                          <FormHelperText>{validation.errors.age}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <FormControl
                        fullWidth
                        error={validation.touched.gender && validation.errors.gender}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Gender
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <RadioGroup
                          defaultValue={validation.gender}
                          value={validation.values.gender || ""}
                          name="gender"
                          onChange={validation.handleChange}
                        >
                          <MKBox sx={{ display: "flex", alignItems: "center" }}>
                            <MKBox sx={{ display: "flex", alignItems: "center" }}>
                              <Radio value="female" label="Female" />{" "}
                              <Typography sx={{ fontSize: "1rem" }}>Female</Typography>
                            </MKBox>
                            <MKBox sx={{ display: "flex", alignItems: "center" }}>
                              <Radio value="male" label="Male" />{" "}
                              <Typography sx={{ fontSize: "1rem" }}>Male</Typography>
                            </MKBox>
                          </MKBox>
                        </RadioGroup>
                        {validation.touched.gender && validation.errors.gender ? (
                          <FormHelperText>{validation.errors.gender}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <FormControl
                        fullWidth
                        error={validation.touched.fatherName && validation.errors.fatherName}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Father Name
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="fatherName"
                          fullWidth
                          value={validation.values.fatherName || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.fatherName && validation.errors.fatherName ? (
                          <FormHelperText>{validation.errors.fatherName}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <FormControl
                        fullWidth
                        error={validation.touched.mobileNo && validation.errors.mobileNo}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Mobile Number
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="mobileNo"
                          type="number"
                          fullWidth
                          value={validation.values.mobileNo || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.mobileNo && validation.errors.mobileNo ? (
                          <FormHelperText>{validation.errors.mobileNo}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <FormControl
                        fullWidth
                        error={
                          validation.touched.alternateMobileNo &&
                          validation.errors.alternateMobileNo
                        }
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Alternate Mobile Number
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="alternateMobileNo"
                          type="number"
                          fullWidth
                          value={validation.values.alternateMobileNo || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.alternateMobileNo &&
                        validation.errors.alternateMobileNo ? (
                          <FormHelperText>{validation.errors.alternateMobileNo}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <FormControl
                        fullWidth
                        error={validation.touched.experience && validation.errors.experience}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Experience (in years)
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="experience"
                          fullWidth
                          type="number"
                          value={validation.values.experience || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.experience && validation.errors.experience ? (
                          <FormHelperText>{validation.errors.experience}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <FormControl
                        fullWidth
                        error={
                          validation.touched.currentAddress && validation.errors.currentAddress
                        }
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Current Address
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="currentAddress"
                          fullWidth
                          value={validation.values.currentAddress || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.currentAddress && validation.errors.currentAddress ? (
                          <FormHelperText>{validation.errors.currentAddress}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <FormControl
                        fullWidth
                        error={
                          validation.touched.permanentAddress && validation.errors.permanentAddress
                        }
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Permanent Address
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="permanentAddress"
                          fullWidth
                          value={validation.values.permanentAddress || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.permanentAddress &&
                        validation.errors.permanentAddress ? (
                          <FormHelperText>{validation.errors.permanentAddress}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <FormControl
                        fullWidth
                        error={validation.touched.qualification && validation.errors.qualification}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Qualification
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="qualification"
                          fullWidth
                          value={validation.values.qualification || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.qualification && validation.errors.qualification ? (
                          <FormHelperText>{validation.errors.qualification}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <FormControl
                        fullWidth
                        error={validation.touched.prefferedArea && validation.errors.prefferedArea}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Preffered Area
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="prefferedArea"
                          fullWidth
                          value={validation.values.prefferedArea || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.prefferedArea && validation.errors.prefferedArea ? (
                          <FormHelperText>{validation.errors.prefferedArea}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <FormControl
                        fullWidth
                        error={validation.touched.maritalStatus && validation.errors.maritalStatus}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Marital Status
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <RadioGroup
                          defaultValue={validation.maritalStatus}
                          value={validation.values.maritalStatus || ""}
                          name="maritalStatus"
                          onChange={validation.handleChange}
                        >
                          <MKBox sx={{ display: "flex", alignItems: "center" }}>
                            <MKBox sx={{ display: "flex", alignItems: "center" }}>
                              <Radio value="married" label="Married" />{" "}
                              <Typography sx={{ fontSize: "1rem" }}>Married</Typography>
                            </MKBox>
                            <MKBox sx={{ display: "flex", alignItems: "center" }}>
                              <Radio value="unmarried" label="Unmarried" />{" "}
                              <Typography sx={{ fontSize: "1rem" }}>Unmarried</Typography>
                            </MKBox>
                          </MKBox>
                        </RadioGroup>
                        {validation.touched.maritalStatus && validation.errors.maritalStatus ? (
                          <FormHelperText>{validation.errors.maritalStatus}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <FormControl
                        fullWidth
                        error={validation.touched.regDate && validation.errors.regDate}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Date of Registration
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="regDate"
                          type="date"
                          fullWidth
                          value={validation.values.regDate || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.regDate && validation.errors.regDate ? (
                          <FormHelperText>{validation.errors.regDate}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <FormControl
                        fullWidth
                        error={validation.touched.profile && validation.errors.profile}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Profile / Job Type
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="profile"
                          fullWidth
                          value={validation.values.profile || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.profile && validation.errors.profile ? (
                          <FormHelperText>{validation.errors.profile}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <FormControl
                        fullWidth
                        error={
                          validation.touched.salaryExpected && validation.errors.salaryExpected
                        }
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Salary expected (in thousand/month)
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          name="salaryExpected"
                          type="number"
                          fullWidth
                          value={validation.values.salaryExpected || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.salaryExpected && validation.errors.salaryExpected ? (
                          <FormHelperText>{validation.errors.salaryExpected}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <MKBox>
                        <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                          Upload Id proof (in pdf only, max size: 100KB)
                          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                        </Typography>
                        <Input type="file" onChange={handleFileChange} />
                      </MKBox>
                    </Grid>
                  </Grid>
                </MKBox>
                <Divider flexItem orientation="horizontal" sx={{ mt: 3 }} />
                <Grid container>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ mt: 1 }}>
                    <Typography>
                      Note : Fields Marked with asterisk(<span style={{ color: "red" }}>*</span>)
                      are mandatory
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}
                  >
                    <MKBox sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Button
                        variant="contained"
                        sx={{ textTransform: "none", color: "#fff" }}
                        color="primary"
                        onClick={() => validation.submitForm()}
                      >
                        Submit
                        {submitLoader && <CircularProgress size={20} sx={{ ml: 1 }} />}
                      </Button>
                    </MKBox>
                  </Grid>
                </Grid>
              </form>
            </MKBox>
            {/* )} */}
          </>
        )}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default JobPlacement;
