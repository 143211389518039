import MKBox from "components/MKBox";
import React from "react";
import bruceMars from "assets/images/Portrait_Placeholder.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addTeacher } from "firebaseFunctions";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

function TeachersList() {
  const [event, setEvent] = React.useState();
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [image, setImage] = React.useState(bruceMars);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      photo: (event && event.photo) || bruceMars,
      name: (event && event.name) || "",
      experience: (event && event.experience) || "",
      qualification: (event && event.qualification) || "",
      subjects: (event && event.subjects) || "",
    },
    validationSchema: Yup.object({
      photo: Yup.string().required("Field is required"),
      name: Yup.string().required("Field is required"),
      experience: Yup.string().required("Field is required"),
      qualification: Yup.string().required("Field is required"),
      subjects: Yup.string().required("Field is required"),
    }),

    onSubmit: async (values) => {
      setSubmitLoader(true);
      try {
        const result = await addTeacher(values);
        setSubmitLoader(false);
        setImage(bruceMars);
        setEvent({});
        validation.resetForm();
        alert("Submit successfully");
        console.log(result);
      } catch (error) {
        console.log(error);
        setSubmitLoader(false);
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 100000) {
      alert("Size is not more than 100KB");
    } else if (
      file.type != "image/png" &&
      file.type != "image/jpg" &&
      file.type != "image/jpeg" &&
      file.type != "image/webp"
    ) {
      alert("Image should be png, jpg, jpeg or webp");
    } else {
      setImage(URL.createObjectURL(file));
      validation.setFieldValue("photo", file);
    }
  };

  return (
    <MKBox sx={{ p: 1 }}>
      {submitLoader ? (
        <MKBox
          sx={{ display: "flex", justifyContent: "center", alignItems: " center", height: 400 }}
        >
          <CircularProgress />
        </MKBox>
      ) : (
        <MKBox sx={{ ml: 2 }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <MKBox>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                  <FormControl
                    fullWidth
                    error={validation.touched.photo && validation.errors.photo}
                  >
                    <MKBox sx={{ width: "200px", height: "200px", border: "1px solid #000" }}>
                      <img src={image} alt="Uploaded" style={{ width: "200px", height: "200px" }} />
                    </MKBox>
                    <MKBox>
                      <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                        Upload your photo (max size 100KB and format png, jpg, jpeg and webp)
                        <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                      </Typography>
                      <Input type="file" onChange={handleImageChange} />
                    </MKBox>
                    {validation.touched.photo && validation.errors.photo ? (
                      <FormHelperText>{validation.errors.photo}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <FormControl fullWidth error={validation.touched.name && validation.errors.name}>
                    <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                      Name
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      name="name"
                      fullWidth
                      value={validation.values.name || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormHelperText>{validation.errors.name}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <FormControl
                    fullWidth
                    error={validation.touched.experience && validation.errors.experience}
                  >
                    <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                      Experience (in years)
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      name="experience"
                      type="number"
                      fullWidth
                      value={validation.values.experience || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.touched.experience && validation.errors.experience ? (
                      <FormHelperText>{validation.errors.experience}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <FormControl
                    fullWidth
                    error={validation.touched.qualification && validation.errors.qualification}
                  >
                    <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                      Qualification
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      name="qualification"
                      fullWidth
                      value={validation.values.qualification || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.touched.qualification && validation.errors.qualification ? (
                      <FormHelperText>{validation.errors.qualification}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <FormControl
                    fullWidth
                    error={validation.touched.subjects && validation.errors.subjects}
                  >
                    <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                      Class and subjects to be taught
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      name="subjects"
                      fullWidth
                      value={validation.values.subjects || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.touched.subjects && validation.errors.subjects ? (
                      <FormHelperText>{validation.errors.subjects}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
            </MKBox>
            <Divider flexItem orientation="horizontal" sx={{ mt: 3 }} />
            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ mt: 1 }}>
                <Typography>
                  Note : Fields Marked with asterisk(<span style={{ color: "red" }}>*</span>) are
                  mandatory
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}
              >
                <MKBox sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "none", color: "#fff" }}
                    color="primary"
                    onClick={() => validation.submitForm()}
                  >
                    Submit
                    {submitLoader && <CircularProgress size={20} sx={{ ml: 1 }} />}
                  </Button>
                </MKBox>
              </Grid>
            </Grid>
          </form>
        </MKBox>
      )}
    </MKBox>
  );
}

export default TeachersList;
