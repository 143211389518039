import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage, auth } from "./firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { signInWithEmailAndPassword } from "firebase/auth";
// import { newDAta } from "newData";
// import { prevData } from "prevdata";
// import { preJobdata } from "prejob";

const uploadImage = async (image) => {
  return new Promise((resolve, reject) => {
    // Allowing file type
    const document = new Date().getTime().toString();
    const iconRef = ref(storage, `images/${document}_${image.name}`);
    const uploadTask = uploadBytesResumable(iconRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch(() => reject());
      }
    );
  });
};

const uploadFile = async (file) => {
  return new Promise((resolve, reject) => {
    // Allowing file type
    const document = new Date().getTime().toString();
    const iconRef = ref(storage, `ids/${document}_${file.name}`);
    const uploadTask = uploadBytesResumable(iconRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch(() => reject());
      }
    );
  });
};

export const writeTutorData = async (data) => {
  const photoUrl = await uploadImage(data.photo);
  const idProofUrl = await uploadFile(data.idProof);
  data.photo = photoUrl;
  data.idProof = idProofUrl;
  const docRef = await addDoc(collection(db, "pretutors"), data);
  console.log(docRef);
};

const uploadPlacementImage = async (image) => {
  return new Promise((resolve, reject) => {
    // Allowing file type
    const document = new Date().getTime().toString();
    const iconRef = ref(storage, `imagesPlacement/${document}_${image.name}`);
    const uploadTask = uploadBytesResumable(iconRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch(() => reject());
      }
    );
  });
};

const uploadPlacementFile = async (file) => {
  return new Promise((resolve, reject) => {
    // Allowing file type
    const document = new Date().getTime().toString();
    const iconRef = ref(storage, `idsPlacement/${document}_${file.name}`);
    const uploadTask = uploadBytesResumable(iconRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch(() => reject());
      }
    );
  });
};

export const writePlacementData = async (data) => {
  const photoUrl = await uploadPlacementImage(data.photo);
  const idProofUrl = await uploadPlacementFile(data.idProof);
  data.photo = photoUrl;
  data.idProof = idProofUrl;
  const docRef = await addDoc(collection(db, "preJob"), data);
  console.log(docRef);
};

export const getTeachersDetails = async () => {
  const q = query(collection(db, "teachersDetails"));
  const result = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const tempData = doc.data();
    tempData["id"] = doc.id;
    result.push(tempData);
  });
  return result;
};

export const getSchoolTeachersDetails = async () => {
  const q = query(collection(db, "schoolTeachers"));
  const result = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const tempData = doc.data();
    tempData["id"] = doc.id;
    result.push(tempData);
  });
  return result;
};

export const getResults = async () => {
  const docRef = doc(db, "results", "resultsPhoto");
  const docSnap = await getDoc(docRef);
  let result = [];
  if (docSnap.exists()) {
    result = docSnap.data().data;
  } else {
    result = [];
    console.log("No such document!");
  }
  return result;
};

export const getYoutubeLinks = async () => {
  const docRef = doc(db, "youtubeLinks", "you-tube-links");
  const docSnap = await getDoc(docRef);
  let result = [];
  if (docSnap.exists()) {
    result = docSnap.data().data;
  } else {
    result = [];
    console.log("No such document!");
  }
  return result;
};

const setTokenDetails = async (data) => {
  await setDoc(doc(db, "userDetails", "user-details"), {
    email: data.email,
    accessToken: data.accessToken,
    uid: data.uid,
  });
};

export const verifyToken = async (token) => {
  const docRef = doc(db, "userDetails", "user-details");
  const docSnap = await getDoc(docRef);
  let response = "";
  if (docSnap.exists()) {
    const result = docSnap.data().accessToken;
    if (result === token) {
      response = "success";
    } else {
      response = "failed";
    }
  } else {
    response = "failed";
    console.log("No such document!");
  }
  return response;
};

export const login = async (username, password) => {
  const response = signInWithEmailAndPassword(auth, username, password)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user;
      localStorage.setItem("accessToken", user.accessToken);
      await setTokenDetails(user);
      return "success";
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error, errorMessage, errorCode);
    });
  // const docRef = doc(db, "userDetails", "user-details");
  // const docSnap = await getDoc(docRef);
  // let result = {};
  // let response = "";
  // if (docSnap.exists()) {
  //   result = docSnap.data();
  //   if (result.username === username && result.password === password) {
  //     response = "success";
  //   } else {
  //     response = "failed";
  //   }
  // } else {
  //   result = [];
  //   response = "failed";
  //   console.log("No such document!");
  // }
  return response;
};

export const getTutorsList = async () => {
  const q = query(collection(db, "pretutors"), orderBy("regDate", "desc"));
  const result = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const tempData = doc.data();
    tempData["id"] = doc.id;
    result.push(tempData);
  });
  return result;
};

export const getPlacementList = async () => {
  const q = query(collection(db, "preJob"));
  const result = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const tempData = doc.data();
    tempData["id"] = doc.id;
    result.push(tempData);
  });
  return result;
};

const uploadTeacherImage = async (image) => {
  return new Promise((resolve, reject) => {
    // Allowing file type
    const document = new Date().getTime().toString();
    const iconRef = ref(storage, `teachers/${document}_${image.name}`);
    const uploadTask = uploadBytesResumable(iconRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch(() => reject());
      }
    );
  });
};

export const addTeacher = async (data) => {
  const document = new Date().getTime().toString();
  const photoUrl = await uploadTeacherImage(data.photo);
  data.photo = photoUrl;
  await setDoc(doc(db, "teachersDetails", document), data);
};

const uploadSchoolTeacherImage = async (image) => {
  return new Promise((resolve, reject) => {
    // Allowing file type
    const document = new Date().getTime().toString();
    const iconRef = ref(storage, `schoolTeachers/${document}_${image.name}`);
    const uploadTask = uploadBytesResumable(iconRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch(() => reject());
      }
    );
  });
};

export const addSchoolTeacher = async (data) => {
  const document = new Date().getTime().toString();
  const photoUrl = await uploadSchoolTeacherImage(data.photo);
  data.photo = photoUrl;
  await setDoc(doc(db, "schoolTeachers", document), data);
};

const uploadResultImage = async (image) => {
  return new Promise((resolve, reject) => {
    // Allowing file type
    const document = new Date().getTime().toString();
    const iconRef = ref(storage, `results/${document}_${image.name}`);
    const uploadTask = uploadBytesResumable(iconRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch(() => reject());
      }
    );
  });
};

export const addResults = async (data) => {
  const oldData = await getResults();
  const photoUrl = await uploadResultImage(data.photo);
  oldData.push(photoUrl);
  await updateDoc(doc(db, "results", "resultsPhoto"), {
    data: oldData,
  });
};

export const addYoutubeLinks = async (link) => {
  const oldData = await getYoutubeLinks();
  oldData.push(link);
  await updateDoc(doc(db, "youtubeLinks", "you-tube-links"), {
    data: oldData,
  });
};

// function getAge(dateString) {
//   var today = new Date();
//   var birthDate = new Date(dateString && dateString.split("-").reverse().join("-"));
//   var age = today.getFullYear() - birthDate.getFullYear();
//   var m = today.getMonth() - birthDate.getMonth();
//   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//     age--;
//   }
//   return age;
// }

// export const addOldTutors = async () => {
//   newDAta.forEach(async (element) => {
// const newData = {
//   photo: "",
//   name: element["Name"],
//   mobileNo: element["Mobile Number"],
//   alternateMobileNo: element["Alternate Mobile Number"],
//   age: element["Age"],
//   gender: element["Sex"],
//   address: element["Present Address"],
//   areaVisit: element["Area of visit"],
//   declaration: true,
//   email: element["Email"],
//   engComm: element["Are you 100% Fluent in English Communication"],
//   experience: element["Experience"],
//   fees: element["Minimum Fee expected by teachers as per class"],
//   idProof: "",
//   maritalStatus: element["Marital Status"],
//   qualification: element["Qualification"],
//   refrence: element["referrer"],
//   regDate: element["Time"].split(" ")[0],
//   subjects: element["Class and subject to be taught"],
//   transportMode: element["Transportation Mode"],
// };
//   const docRef = await addDoc(collection(db, "pretutors"), element);
//   console.log("Document written with ID: ", docRef.id);
// });
// };
