import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import MKBox from "components/MKBox";
import { addYoutubeLinks } from "firebaseFunctions";
import React from "react";

function YoutubeLinks() {
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [link, setLink] = React.useState("");

  const handleSubmit = async () => {
    setSubmitLoader(true);
    try {
      const result = await addYoutubeLinks(link);
      setSubmitLoader(false);
      setLink("");
      alert("Submit successfully");
      console.log(result);
    } catch (error) {
      console.log(error);
      setSubmitLoader(false);
    }
  };

  return (
    <MKBox sx={{ p: 1 }}>
      {submitLoader ? (
        <MKBox
          sx={{ display: "flex", justifyContent: "center", alignItems: " center", height: 400 }}
        >
          <CircularProgress />
        </MKBox>
      ) : (
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ mt: 1 }}>
            <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
              Put the your youtube link here.
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              name="link"
              type="text"
              fullWidth
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}
          >
            <MKBox sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button
                variant="contained"
                sx={{ textTransform: "none", color: "#fff" }}
                color="primary"
                onClick={handleSubmit}
              >
                Submit
                {submitLoader && <CircularProgress size={20} sx={{ ml: 1 }} />}
              </Button>
            </MKBox>
          </Grid>
        </Grid>
      )}
    </MKBox>
  );
}
export default YoutubeLinks;
