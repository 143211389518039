// Pages
import AboutUs from "pages/LandingPages/AboutUs";
import ContactUs from "pages/LandingPages/ContactUs";
import Teachers from "pages/LandingPages/Teachers";
import Results from "pages/LandingPages/Results";
import TutorRegistration from "pages/LandingPages/TutorRegistration";
import JobPlacement from "pages/LandingPages/JobPlacement";
// import SignInBasic from "pages/LandingPages/SignIn";
// import Admin from "pages/LandingPages/Admin";

const routes = [
  {
    name: "tutor registration",
    route: "/tutor-registration",
    component: <TutorRegistration />,
  },
  {
    name: "job placement",
    route: "/job-placement",
    component: <JobPlacement />,
  },
  {
    name: "teachers",
    route: "/teachers",
    component: <Teachers />,
  },
  {
    name: "results",
    route: "/results",
    component: <Results />,
  },
  {
    name: "about us",
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "contact us",
    route: "/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
