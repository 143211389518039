// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import React from "react";
import { getYoutubeLinks } from "firebaseFunctions";

function DesignBlocks() {
  const [data, setData] = React.useState([]);

  const getData = async () => {
    try {
      const res = await getYoutubeLinks();
      console.log(res);
      setData(res);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Welcome to A1 Home Tutors
          </MKTypography>
          <MKTypography variant="body1" color="text">
            A1 Institute is the foremost educational platform to performing the best ettiquote to
            reforming structural development of Educational Volumes.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>
        <Grid container spacing={3} sx={{ mb: 10 }}>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                A1 Home Tutors Videos
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Educational videos
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={3}>
              {data.map((image) => (
                <Grid item xs={12} md={4} sx={{ mb: 2 }} key={image}>
                  <ExampleCard image={image} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DesignBlocks;
