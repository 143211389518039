import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Helmet } from "react-helmet";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <Helmet>
      <meta
        name="description"
        content="A1 Home tutor & A1 Institue & A1 Play School. Best in rohini sector 21. Home Tuition we provided and best home tutor we provide in Delhi NCR (Noida, Ghaziabad, Gurugram). Successfully running best play school in rohini sec 21 that is a1 play school in rohini."
      />
      <meta
        name="keywords"
        content="female home tutor near me, home tuition near me, home tuition delhi, home tuition job, home tuition teacher,kidzee play school near me, best play school near me, home tuition for class 1 to 5, home tuition service, home tuition bureau near me,home tuition provider near me, home tuition teacher near me, home tuition delhi, home tuition near me justdial, home tutors in delhi, play school near me,play school delhi,play school nangloi,play school rohini,play school delhi,pre school delhi ny,play school near me open now,  Home Tuition, home tutor, play school, home tution, home tutors, a1 home tutors, a1 home tuition, best home tuition, best home tutors, home tuition rohini, rohini sec 21, rohini, sec 21, sector 21, play school, kinder, kindergarten, primary school, school, rohini school, best play school, institute, education, educational institute, educational"
      />
    </Helmet>
    <App />
  </BrowserRouter>
);
