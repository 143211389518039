import MKBox from "components/MKBox";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getTutorsList } from "firebaseFunctions";
import { Button, CircularProgress } from "@mui/material";
import Papa from "papaparse";
import bruceMars from "assets/images/Portrait_Placeholder.png";

const columns = [
  {
    field: "photo",
    headerName: "Photo",
    width: 70,
    renderCell: (params) => (
      <img
        src={params.row.photo ? params.row.photo : bruceMars}
        style={{ width: "40px", height: "40px", borderRadius: "50%" }}
      />
    ),
  },
  { field: "name", headerName: "Name", width: 150 },
  { field: "mobileNo", headerName: "Mobile No", width: 120 },
  { field: "alternateMobileNo", headerName: "Alternate Mobile No", width: 120 },
  {
    field: "age",
    headerName: "Age",
    width: 50,
  },
  {
    field: "gender",
    headerName: "Gender",
    width: 90,
  },
  { field: "address", headerName: "Address", width: 150 },
  { field: "areaVisit", headerName: "Area Visit", width: 150 },
  { field: "email", headerName: "Email", width: 120 },
  { field: "engComm", headerName: "Eng Comm", width: 150 },
  { field: "experience", headerName: "Experience", width: 120 },
  { field: "fees", headerName: "Fees expected", width: 150 },
  { field: "maritalStatus", headerName: "Marital Status", width: 120 },
  { field: "qualification", headerName: "Qualification", width: 150 },
  { field: "refrence", headerName: "Refrence", width: 120 },
  { field: "regDate", headerName: "RegDate", width: 150 },
  { field: "subjects", headerName: "Class / Subjects", width: 120 },
  { field: "transportMode", headerName: "Transport Mode", width: 150 },
];

function TutorList() {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  const getTutorsLists = async () => {
    setLoader(true);
    try {
      const res = await getTutorsList();
      setData(res);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    const newData = data.map((item) => {
      return {
        name: item.name,
        email: item.email,
        refrence: item.refrence,
        age: item.age,
        gender: item.gender,
        regDate: item.regDate,
        mobileNo: item.mobileNo,
        alternateMobileNo: item.alternateMobileNo,
        experience: item.experience,
        address: item.address,
        subjects: item.subjects,
        qualification: item.qualification,
        areaVisit: item.areaVisit,
        maritalStatus: item.maritalStatus,
        engComm: item.engComm,
        transportMode: item.transportMode,
        fees: item.fees,
      };
    });
    const csvData = Papa.unparse(newData);
    exportData(csvData, "tutors.csv", "text/csv;charset=utf-8;");
  };

  React.useEffect(() => {
    getTutorsLists();
  }, []);

  return (
    <>
      <MKBox sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" sx={{ color: "#fff" }} onClick={handleDownload}>
          Download All Data
        </Button>
      </MKBox>
      <MKBox sx={{ p: 1, height: 650, width: "100%" }}>
        {loader ? (
          <MKBox
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 450 }}
          >
            <CircularProgress />
          </MKBox>
        ) : (
          data.length > 0 && (
            <DataGrid
              rows={data}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          )
        )}
      </MKBox>
    </>
  );
}

export default TutorList;
