// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/bg-presentation.webp";
import { Typography } from "@mui/material";

function ContactUs() {
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/login",
            label: "login",
            color: "info",
          }}
          sticky
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                For further questions, please contact on this address and phone number.
              </MKTypography>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>Phone: </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
                    +91 9868420099 / 8076311592 / 8920556391 / 9899795709
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>Mail: </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
                    <a href="mailto:murlidharjha43@gmail.com">murlidharjha43@gmail.com </a>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>Main Branch: </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
                    Plot no 6 pocket 5 sector 21 Rohini Delhi 110086
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
                    Second Branch :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 400 }}>
                    Plot no 138 second floor pocket 5 sector 21 Rohini Delhi 110086
                  </Typography>
                </Grid>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
