// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Images
import bgImage from "assets/images/bg-presentation.webp";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { Grid, Tab, Tabs } from "@mui/material";
import adminRoutes from "admin.routes";
import React from "react";
import TutorList from "./Tabs/TutorList";
import JobPlacementList from "./Tabs/JobPlacementList";
import TeachersList from "./Tabs/TeachersList";
import Results from "./Tabs/Results";
import YoutubeLinks from "./Tabs/YoutubeLinks";
import { useNavigate } from "react-router-dom";
import { verifyToken } from "firebaseFunctions";
import SchoolTeachers from "./Tabs/SchoolTeachers";

function Admin() {
  const [filter, setFilter] = React.useState("tutorList");

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setFilter(newValue);
  };

  const verifyDetails = async (token) => {
    try {
      const res = await verifyToken(token);
      if (res !== "success") {
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      verifyDetails(accessToken);
    } else {
      navigate("/admin", { replace: true });
    }
  }, []);
  return (
    <>
      <DefaultNavbar
        routes={adminRoutes}
        transparent
        action={{
          type: "internal",
          route: "/",
          label: "logout",
          color: "info",
        }}
        light
      />
      <MKBox
        width="100%"
        minHeight="20vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Card
        sx={{
          p: 5,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container sx={{ display: "flex", height: "100%", width: "100%" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={1.5}
            xl={1.5}
            sx={{
              width: "100%",
              height: "100%",
              borderRiight: "1px solid",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={filter}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider", height: 435 }}
            >
              <Tab label={"Tutor List"} value="tutorList" />
              <Tab label={"Job Placement List"} value="jobPlacementList" />
              <Tab label={"Teachers List"} value="teachers" />
              <Tab label={"School Teachers List"} value="schoolTeachers" />
              <Tab label={"Results"} value="results" />
              <Tab label={"Youtube Link"} value="youtube" />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={10.5} xl={10.5}>
            {filter === "tutorList" && <TutorList />}
            {filter === "jobPlacementList" && <JobPlacementList />}
            {filter === "teachers" && <TeachersList />}
            {filter === "schoolTeachers" && <SchoolTeachers />}
            {filter === "results" && <Results />}
            {filter === "youtube" && <YoutubeLinks />}
          </Grid>
          {/* <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>Tutor List</Typography>
              <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>Tutor List</Typography>
              <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>Tutor List</Typography>
              <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>Tutor List</Typography>
              <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>Tutor List</Typography>
            </Grid>
          </Grid> */}
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Admin;
